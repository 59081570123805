import { combineReducers } from 'redux';
import tokenReducer from '../components/shared/reducer';
import { screenerReducer, docExplorerReducer, docCompareReducer } from './pages';

const rootReducer = combineReducers({
  tokenReducer,
  screenerReducer,
  docExplorerReducer,
  docCompareReducer
});

export default rootReducer;
