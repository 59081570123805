export const updateFolderDataWithMovedItem = (folders, targetFolderId, draggedItem) => {
  const removeItem = (folderList) => {
    return folderList
      .map((folder) => {
        if (folder.id === draggedItem.id) {
          return null; // Remove the dragged item
        }

        if (folder.node_type === 'folder' && folder.children) {
          return {
            ...folder,
            children: removeItem(folder.children).filter(Boolean) // Recursively process children
          };
        }

        return folder; // Return the folder as is
      })
      .filter(Boolean); // Remove null items
  };

  const addItem = (folderList) => {
    return folderList.map((folder) => {
      if (folder.id === targetFolderId && folder.node_type === 'folder') {
        return {
          ...folder,
          children: [
            ...(folder.children || []),
            { ...draggedItem, parent_folder_id: targetFolderId }
          ]
        };
      }

      if (folder.node_type === 'folder' && folder.children) {
        return {
          ...folder,
          children: addItem(folder.children) // Recursively process children
        };
      }

      return folder; // Return the folder as is
    });
  };

  // First, remove the dragged item from all folders
  const updatedFolders = removeItem(folders);

  // Then, add the dragged item to the target folder
  return addItem(updatedFolders);
};

export const updateFolderDataToRoot = (folders, draggedItem) => {
  // Recursively process the folders to remove the dragged item
  const updatedFolders = folders
    .map((folder) => {
      if (folder.node_type === 'folder' && folder.children) {
        const updatedChildren = updateFolderDataToRoot(folder.children, draggedItem).filter(
          (child) => child.id !== draggedItem.id
        );
        return {
          ...folder,
          children: updatedChildren
        };
      }
      return folder.id === draggedItem.id ? null : folder; // Remove the dragged item if it's at this level
    })
    .filter(Boolean); // Remove any null items

  // Add the dragged item to the root level and update parent_folder_id
  const draggedItemUpdated = {
    ...draggedItem,
    parent_folder_id: null // Mark it as a root item
  };

  return [draggedItemUpdated, ...updatedFolders];
};

export const renameLocalFolder = (folderData, currentFolderId, folderToBeupdate) => {
  if (
    !Array.isArray(folderData) ||
    !currentFolderId ||
    !folderToBeupdate ||
    typeof folderToBeupdate !== 'object'
  ) {
    return folderData;
  }

  // Create a deep copy of folderData
  const deepCopy = (data) =>
    data.map((folder) => ({
      ...folder,
      children: folder.children ? deepCopy(folder.children) : null
    }));

  const copiedData = deepCopy(folderData);

  const updateFolder = (folders, folderId, updatedFolder) => {
    for (let i = 0; i < folders.length; i++) {
      if (folders[i].id === folderId) {
        folders[i] = { ...folders[i], ...updatedFolder };
        return;
      }
      if (Array.isArray(folders[i].children) && folders[i].children.length > 0) {
        updateFolder(folders[i].children, folderId, updatedFolder);
      }
    }
  };

  updateFolder(copiedData, currentFolderId, folderToBeupdate);

  return copiedData;
};

export const updateFolderChildren = (folderData, parentFolderId, newChildren) => {
  if (!Array.isArray(folderData) || !parentFolderId || !Array.isArray(newChildren)) {
    return folderData;
  }

  // Create a deep copy of folderData
  const deepCopy = (data) =>
    data.map((folder) => ({
      ...folder,
      children: folder.children ? deepCopy(folder.children) : null
    }));

  const copiedData = deepCopy(folderData);

  const updateChildren = (folders, folderId, children) => {
    for (let i = 0; i < folders.length; i++) {
      if (folders[i].id === folderId) {
        // Update children for the matching folder
        folders[i] = {
          ...folders[i],
          children: children
        };
        return;
      }
      if (Array.isArray(folders[i].children) && folders[i].children.length > 0) {
        updateChildren(folders[i].children, folderId, children);
      }
    }
  };

  updateChildren(copiedData, parentFolderId, newChildren);

  return copiedData;
};

export const removeFolderById = (folderData, folderIdToRemove) => {
  if (!Array.isArray(folderData) || !folderIdToRemove) {
    return folderData;
  }

  const deepCopy = (data) =>
    data.map((folder) => ({
      ...folder,
      children: folder.children ? deepCopy(folder.children) : null
    }));

  const copiedData = deepCopy(folderData);

  const removeFolder = (folders, folderId) => {
    return folders
      .filter((folder) => folder.id !== folderId)
      .map((folder) => ({
        ...folder,
        children: folder.children ? removeFolder(folder.children, folderId) : null
      }));
  };

  return removeFolder(copiedData, folderIdToRemove);
};

export const removeFile = (folderData, file) => {
  if (!Array.isArray(folderData) || !file || !file.id) {
    return folderData;
  }

  if (file.parent_folder_id === null) {
    return folderData.filter((folder) => folder.id !== file.id);
  }

  // Recursive function to remove file from nested folders
  const removeFileFromFolder = (folders, fileId, parentFolderId) => {
    return folders.map((folder) => {
      // If current folder matches parent_folder_id, filter out the file from its children
      if (folder.id === parentFolderId) {
        return {
          ...folder,
          children: folder.children
            ? folder.children.filter((child) => child.id !== fileId)
            : folder.children
        };
      }

      // Recurse into children if they exist
      if (folder.children) {
        return {
          ...folder,
          children: removeFileFromFolder(folder.children, fileId, parentFolderId)
        };
      }

      return folder; // Return folder unmodified if no changes are needed
    });
  };

  // Start recursive removal
  return removeFileFromFolder(folderData, file.id, file.parent_folder_id);
};

export const mergeFolderData = (previousFolderData, newFolderData) => {
  if (!Array.isArray(previousFolderData) || !Array.isArray(newFolderData)) {
    return previousFolderData;
  }

  const existingIds = new Set(previousFolderData.map((folder) => folder.id));

  const missingFolders = newFolderData.filter((folder) => !existingIds.has(folder.id));

  return [...missingFolders, ...previousFolderData];
};
