import React from 'react';
import { Grid } from '@mui/material';
import { Link } from '@material-ui/core';

const SocialMedia = ({ socialIconsData, classes }) => {
  const formatUrl = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };
  return (
    <Grid item xs={11} className={classes.displayInline} id="companySocialMedia">
      {socialIconsData.Linkedin ? (
        <div className={classes.iconsMainDiv}>
          <Link
            target="_blank"
            href={formatUrl(socialIconsData.Linkedin)}
            className={classes.iconParent}
          >
            <img
              src="/images/icons/Linkedin - Icon.svg"
              className={classes.socialMediaIcon}
              alt=""
            />
          </Link>
        </div>
      ) : null}
      {socialIconsData.Twitter ? (
        <div className={classes.iconsMainDiv}>
          <Link
            target="_blank"
            href={formatUrl(socialIconsData.Twitter)}
            className={classes.iconParent}
          >
            <img
              src="/images/icons/Twitter - Icon.svg"
              className={classes.socialMediaIcon}
              alt=""
            />
          </Link>
        </div>
      ) : null}
      {socialIconsData.Facebook ? (
        <div className={classes.iconsMainDiv}>
          <Link
            target="_blank"
            href={formatUrl(socialIconsData.Facebook)}
            className={classes.iconParent}
          >
            <img
              src="/images/icons/Facebook - Icon.svg"
              className={classes.socialMediaIcon}
              alt=""
            />
          </Link>
        </div>
      ) : null}
      {socialIconsData.Instagram ? (
        <div className={classes.iconsMainDiv}>
          <Link target="_blank" href={socialIconsData.Instagram} className={classes.iconParent}>
            <i
              className={`fa fa-instagram ${classes.socialMediaIcon}`}
              style={{ color: '#0380e2' }}
            ></i>
          </Link>
        </div>
      ) : null}
    </Grid>
  );
};

export default SocialMedia;
