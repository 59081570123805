import React from 'react';
import { deleteApi } from '../../../../../services/token';
import DeleteDialog from '../../../../atoms/delete-dialog';
import { useDispatch } from 'react-redux';
import {
  setDocexplorerLoadingDc,
  setFolderDataDc
} from '../../../../../redux/pages/docCompare/action';
import { dispatchHideShowMessage } from '../../../../../helpers';
import { setGlobalErrorMessage, setGlobalSuccessMessage } from '../../../action';
import { removeFile } from '../helper';

const ingestionUrl = process.env.REACT_APP_INGESTION_API_URL;
const FileDeleteDialog = ({
  openDeletePopUp,
  setOpenDeletePopUp,
  setDeleteFileData,
  file,
  folderData
}) => {
  const dispatch = useDispatch();

  const handleCloseDeletePopUp = () => {
    setOpenDeletePopUp(false);
    setDeleteFileData(null);
  };

  const handleDelete = async () => {
    const folderId = file;
    if (folderId) {
      try {
        dispatch(setDocexplorerLoadingDc(true));
        await deleteApi(`${ingestionUrl}/v1/document/${file.id}`);
        const newFolderData = removeFile(folderData, file);
        dispatch(setFolderDataDc(newFolderData));
        dispatchHideShowMessage(
          dispatch,
          `${file.name}.${file?.details?.file_format} file deleted successfully`,
          setGlobalSuccessMessage,
          3000
        );
        handleCloseDeletePopUp();
        dispatch(setDocexplorerLoadingDc(false));
      } catch (error) {
        dispatchHideShowMessage(
          dispatch,
          error?.response?.data?.error?.detail ||
            error.message ||
            'Something went wrong , please try again later',
          setGlobalErrorMessage,
          3000
        );
        dispatch(setDocexplorerLoadingDc(false));
      }
    }
  };
  return (
    <>
      <DeleteDialog
        openState={openDeletePopUp}
        handleClose={handleCloseDeletePopUp}
        text="File"
        content="Are you sure you want to delete this file. This will be deleted permanently."
        trigger={handleDelete}
      />
    </>
  );
};

export default FileDeleteDialog;
