import { postApi } from '../../../services/token';
import { setScreenerData } from '../../../redux/pages/screener/action';
import { setTableDataErrorMessage } from '../../../redux/pages/screener/action';

export const getScreenrTableData = (dispatch, screenerData) => async (body, params) => {
  const timeout = 300000;
  const timeoutErrorMessage = 'Request timed out. Please try again later.';

  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error(timeoutErrorMessage));
    }, timeout);
  });

  try {
    const updatedScreenerData = screenerData.map((screenerItem) =>
      screenerItem.active ? { ...screenerItem, isLoading: true } : screenerItem
    );
    dispatch(setScreenerData(updatedScreenerData));

    const screenrTableData = await Promise.race([
      postApi(`${process.env.REACT_APP_API_BASE_LB}/server/screener/query/response${params}`, body),
      timeoutPromise
    ]);

    return screenrTableData;
  } catch (error) {
    const errorMessage =
      error.message === timeoutErrorMessage
        ? timeoutErrorMessage
        : 'Something went wrong. Please try again later.';

    const updatedScreenerData = screenerData.map((screenerItem) =>
      screenerItem.active
        ? {
            ...screenerItem,
            isLoading: false,
            tableData: []
          }
        : screenerItem
    );
    dispatch(setScreenerData(updatedScreenerData));
    dispatch(setTableDataErrorMessage(errorMessage));
    setTimeout(() => {
      dispatch(setTableDataErrorMessage(''));
    }, 3000);
    console.error(error);
  }
};
