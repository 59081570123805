import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setScreenerData } from '../../../../redux/pages/screener/action';
import Typography from '@material-ui/core/Typography';
import { Button } from '@mui/material';
import useStyles from '../../styles';
import { getScreenrTableData } from '../../services';

const QueryActionBtns = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const screenerData = useSelector((state) => state.screenerReducer.screenerData);
  const selectedTabData = screenerData.find((item) => item.active === true);
  const uuid = useSelector((state) => state.tokenReducer.uuID);

  const handleSaveQueryBtn = () => {
    console.log('save');
  };

  const handleResetBtn = useCallback(() => {
    const updatedScreenerData = screenerData.map((screenerItem) =>
      screenerItem.active ? { ...screenerItem, queryText: '' } : screenerItem
    );
    dispatch(setScreenerData(updatedScreenerData));
  }, [dispatch, screenerData]);

  const handleRunScreenerBtn = useCallback(async () => {
    let body = {
      entity_type: selectedTabData.entityType,
      query_type: selectedTabData.filterType,
      query_details: {
        query_text: selectedTabData.queryText
      },
      session_id: uuid
    };
    let params = `?page_number=${1}&page_size=${selectedTabData.pageSize}&session_id=${uuid}`;
    try {
      const tableData = await getScreenrTableData(dispatch, screenerData)(body, params);

      const updatedScreenerData = screenerData.map((screenerItem) =>
        screenerItem.active
          ? {
              ...screenerItem,
              tableData: tableData.data.data,
              isLoading: false,
              tableDataError: false,
              tableDataErrorMessage: ''
            }
          : screenerItem
      );
      dispatch(setScreenerData(updatedScreenerData));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, screenerData]);

  return (
    <div style={{ paddingTop: '24px' }}>
      <div className={classes.buttonBox}>
        <Button
          disabled={selectedTabData.isLoading}
          style={{ display: 'none' }}
          className={classes.saveQuery}
          onClick={() => handleSaveQueryBtn()}
          disableRipple
        >
          <Typography className={classes.saveQueryTxt}>Save Query</Typography>
        </Button>
        <Button
          disabled={selectedTabData.isLoading}
          style={{ opacity: selectedTabData.isLoading ? 0.4 : 1 }}
          className={classes.resetQuery}
          onClick={() => handleResetBtn()}
          disableRipple
        >
          <Typography className={classes.resetQueryTxt}>Reset</Typography>
        </Button>
        <Button
          disabled={selectedTabData.isLoading}
          style={{ opacity: selectedTabData.isLoading ? 0.4 : 1 }}
          className={classes.runQuery}
          onClick={() => handleRunScreenerBtn()}
          disableRipple
        >
          <Typography className={classes.runQueryTxt}>Run Screener</Typography>
        </Button>
      </div>
    </div>
  );
};

export default QueryActionBtns;
