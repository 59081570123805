import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setScreenerData } from '../../../../redux/pages/screener/action';
import TextQuery from './textQuery';
import useStyles from '../../styles';

const FilterBox = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const screenerFilterTypes = useSelector((state) => state.screenerReducer.screenerFilterTypes);
  const selectedScreenerTab = useSelector((state) => state.screenerReducer.selectedScreenerTab);
  const screenerData = useSelector((state) => state.screenerReducer.screenerData);

  const handleTabChange = (event, newValue) => {
    const updatedScreenerData = screenerData.map((item) =>
      item.entityType === selectedScreenerTab ? { ...item, filterType: newValue } : item
    );

    dispatch(setScreenerData(updatedScreenerData));
  };

  const selectedTabFilterType = screenerData.find(
    (item) => item.entityType === selectedScreenerTab
  )?.filterType;

  console.log(classes);

  return (
    <div
      style={{
        height: '300px',
        background: '#FFFFFF',
        border: '1px solid #E7E7E7',
        borderRadius: '8px',
        padding: '0px 16px 16px 16px'
      }}
    >
      <Tabs
        value={selectedTabFilterType}
        onChange={handleTabChange}
        TabIndicatorProps={{
          sx: {
            bgcolor:
              selectedTabFilterType ===
              screenerFilterTypes.find((filter) => filter.key === selectedTabFilterType)?.key
                ? '#004CFF !important'
                : '#767676 !important',
            height: '1px'
          }
        }}
      >
        {screenerFilterTypes.map((filter) => (
          <Tab
            key={filter.key}
            label={filter.value}
            value={filter.key}
            sx={{
              textTransform: 'none',
              fontFamily: 'Satoshi',
              fontWeight: 'bold',
              fontSize: '14px',
              minWidth: '60px',
              padding: '0px 8px 0px 8px',
              color:
                selectedTabFilterType === filter.key ? '#004CFF !important' : '#767676 !important'
            }}
          />
        ))}
      </Tabs>
      {selectedTabFilterType === 'custom' && <TextQuery />}
    </div>
  );
};

export default FilterBox;
