import {
  SETINTIALSTATEFOLDER,
  SETFOLDERDATA,
  SETDOCEXPLORERLOADING,
  SETGLOBALLOADING,
  SETSELECTEDDOCEXPLORER
} from '../../action.types';

export const setIntialStateFolder = (data) => {
  return {
    type: SETINTIALSTATEFOLDER,
    data
  };
};

export const setFolderData = (data) => {
  return {
    type: SETFOLDERDATA,
    data
  };
};

export const setDocexplorerLoading = (data) => {
  return {
    type: SETDOCEXPLORERLOADING,
    data
  };
};

export const setGlobalLoading = (data) => {
  return {
    type: SETGLOBALLOADING,
    data
  };
};

export const setselectedDocExplorer = (data) => {
  return {
    type: SETSELECTEDDOCEXPLORER,
    data
  };
};
