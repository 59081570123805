import React, { useEffect, useState } from 'react';
// import useStyles from '../styles';
import { useSelector } from 'react-redux';
import TableComp from '../../atoms/table';
import { setScreenerData } from '../../../redux/pages/screener/action';
import { useDispatch } from 'react-redux';
import { getScreenrTableData } from '../services';

const ScreenerTable = () => {
  const dispatch = useDispatch();
  const toggleScreening = useSelector((state) => state.screenerReducer.toggleScreening);
  // const classes = useStyles();
  // const toggleScreening = useSelector((state) => state.screenerReducer.toggleScreening);
  const selectedScreenerTab = useSelector((state) => state.screenerReducer.selectedScreenerTab);
  const screenerData = useSelector((state) => state.screenerReducer.screenerData);

  const uuid = useSelector((state) => state.tokenReducer.uuID);
  const [pageNumberChange, setPageNumberChange] = useState(false);
  const [pageSizeChange, setPageSizeChange] = useState(false);

  const currentTabDataIndex = screenerData.findIndex(
    (screenerTab) => screenerTab.entityType === selectedScreenerTab
  );

  const currentTabData = screenerData[currentTabDataIndex];
  const { pageSize = 30, pageNumber = 1 } = currentTabData;

  const { tableData = {}, isLoading } = currentTabData || {};
  const { items = [], metadata = {} } = tableData || {};
  const { total_records: totalRecords = 1 } = metadata || {};

  const columnHeaders = items.length
    ? Object.keys(items[0]).map((key) => ({
        key,
        displayName: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')
      }))
    : [];

  const emptyState = {
    colCnt: columnHeaders.length,
    component: () => <div>No records found</div>
  };

  const updateScreenerDataAtIndex = (index, property, value) => {
    const updatedScreenerData = screenerData.map((item, i) =>
      i === index
        ? {
            ...item,
            [property]: value
          }
        : item
    );

    dispatch(setScreenerData(updatedScreenerData));
  };

  const updatePagination = (pageNumber, pageSize) => {
    const updatedScreenerData = screenerData.map((item, i) =>
      i === currentTabDataIndex
        ? {
            ...item,
            pageNumber,
            pageSize
          }
        : item
    );
    dispatch(setScreenerData(updatedScreenerData));
  };

  const pageSizeProps = {
    value: pageSize,
    options: [
      { key: 30, value: 30 },
      { key: 50, value: 50 },
      { key: 100, value: 100 }
    ],
    callbackFn: (v) => {
      setPageSizeChange(true);
      updatePagination(1, v);
    },
    label: '',
    placeholder: ''
  };

  const setPageNumber = (pageNumber) => {
    setPageNumberChange(true);
    updateScreenerDataAtIndex(currentTabDataIndex, 'pageNumber', pageNumber);
  };

  // const setPageSize = (pageSize) => {
  //   updateScreenerDataAtIndex(currentTabDataIndex, 'pageSize', pageSize);
  // };

  const handleRunScreenerBtn = async () => {
    if (!currentTabData.queryText) return;
    let body = {
      entity_type: currentTabData.entityType,
      query_type: currentTabData.filterType,
      query_details: {
        query_text: currentTabData.queryText
      },
      session_id: uuid
    };
    let params = `?page_number=${pageNumber}&page_size=${pageSize}&session_id=${uuid}`;
    try {
      const tableData = await getScreenrTableData(dispatch, screenerData)(body, params);
      updateScreenerDataAtIndex(currentTabDataIndex, 'tableData', tableData?.data?.data);
    } catch (error) {
      console.error('Error fetching screener table data:', error);
    }
  };

  const updatedTotalPages = Math.floor((totalRecords - 1) / [pageSize]) + 1;

  useEffect(() => {
    if (pageNumberChange || pageSizeChange) {
      handleRunScreenerBtn();
      setPageNumberChange(false);
      setPageSizeChange(false);
    }
  }, [pageNumber, pageSize]);

  const paginationObj = {
    loading: isLoading,
    updatedTotalPages,
    pageNumber: metadata.page_number,
    setPageNumber,
    pageSizeProps,
    metadata,
    xSmallScreen: false
  };
  const showTable = items && items.length > 0;

  return (
    <div
      style={
        showTable || isLoading
          ? {
              padding: '10px 40px 16px',
              background: '#FFFFFF',
              minHeight: `calc(100vh - ${toggleScreening ? 542 : 240}px)`
            }
          : {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: `calc(100vh - ${toggleScreening ? 542 : 240}px)`
            }
      }
    >
      {/* <Typography className={classes.screenerTxt}>
        <span
          style={{
            backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
        >
          Table
        </span>
      </Typography> */}
      {((items && items.length > 0) || isLoading) && (
        <>
          <TableComp
            tableData={items}
            columnHeaders={columnHeaders}
            loading={isLoading}
            emptyState={emptyState}
            metadata={metadata}
            paginationObj={paginationObj}
          />
        </>
      )}
    </div>
  );
};

export default ScreenerTable;
