import {
  SETINTIALSTATESCREENER,
  SETSCREENERTABS,
  SETACTIVESCREENERTAB,
  SETTOGGLESCREANING,
  SETSCREENERDATA,
  SETTABLEDATAERRORMESSAGE
} from '../../action.types';

const INITIAL_STATE = {
  screenerTabs: [
    { key: 'company', value: 'Company' },
    // { key: 'fund', value: 'Fund' },
    { key: 'people', value: 'People' }
  ],
  selectedScreenerTab: 'company',
  toggleScreening: true,
  screenerFilterTypes: [
    // { key: 'filter', value: 'Filter' },
    { key: 'custom', value: 'Query' }
  ],
  errorMessage: '',
  screenerData: [
    {
      active: true,
      entityType: 'company',
      filterType: 'custom',
      queryText: '',
      tableData: [],
      isLoading: false,
      exampleQueries: [
        'Give me all EBITDA-positive B2B SaaS companies with repeat founders or founders from IIT or IIM.',
        'Give me a list of Indian Gen-AI companies that raised more than 10 crores in 2024.',
        'Give me a list of Series B companies in the beauty and personal care sector focusing on Tier 2 and Tier 3 cities.'
      ],
      pageNumber: 1,
      pageSize: 30
    },
    {
      active: false,
      entityType: 'fund',
      filterType: 'custom',
      queryText: '',
      tableData: [],
      isLoading: false,
      exampleQueries: [
        'Which are the industrializing Machine learning Tech FUNDS.',
        'FUNDS based out of Bangalore.',
        'Which are the industrializing Machine learning Tech FUNDS.',
        'FUNDS based out of Bangalore.'
      ],
      pageNumber: 1,
      pageSize: 30
    },
    {
      active: false,
      entityType: 'people',
      filterType: 'custom',
      queryText: '',
      tableData: [],
      isLoading: false,
      exampleQueries: [
        'people in Machine learning Tech Companies.',
        'AI Based Startup people based out of Bangalore.',
        'people in Machine learning Tech Companies.',
        'AI Based Startup people based out of Bangalore.'
      ],
      pageNumber: 1,
      pageSize: 30
    }
  ]
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETSCREENERTABS:
      return {
        ...state,
        screenerTabs: action.data
      };

    case SETACTIVESCREENERTAB:
      return {
        ...state,
        selectedScreenerTab: action.data
      };

    case SETTOGGLESCREANING:
      return {
        ...state,
        toggleScreening: action.data
      };

    case SETSCREENERDATA:
      return {
        ...state,
        screenerData: action.data
      };

    case SETTABLEDATAERRORMESSAGE:
      return {
        ...state,
        errorMessage: action.data
      };

    case SETINTIALSTATESCREENER:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
