import React, { useEffect, useState } from 'react';
import DynamicDialog from '../../../../atoms/dynamic-dialog';
import { TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  errorCls: {
    color: 'red'
  }
}));

const SaveFolderName = ({
  open = '',
  setOpen = () => {},
  title = 'Create new folder',
  save = () => {},
  currentFolderName = ''
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(currentFolderName || '');
  const [showError, setShowError] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    if (currentFolderName && currentFolderName !== inputValue) {
      setInputValue(currentFolderName);
    }
  }, [currentFolderName]);

  const reset = () => {
    setInputValue('');
    setShowError(false);
    setDisableBtn(false);
  };

  // const handleClickOpen = () => {
  //   reset();
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleFormSubmit = async () => {
    setShowError(true);
    if (inputValue) {
      try {
        setDisableBtn(true);
        await save(inputValue);
        setDisableBtn(false);
        handleClose();
      } catch (error) {
        setDisableBtn(false);
      }
    }
  };

  // const Content = () => (
  //   <div>
  //     <TextField
  //       autoFocus
  //       margin="dense"
  //       id="name"
  //       name="textFieldInput"
  //       label="Folder Name"
  //       type="text"
  //       fullWidth
  //       variant="outlined"
  //       value={inputValue}
  //       onChange={(e) => setInputValue(e.target.value)}
  //     />
  //     {showError && !inputValue && <span className={classes.errorCls}>Required*</span>}
  //   </div>
  // );

  return (
    <>
      <div>
        <DynamicDialog
          title={title}
          open={open}
          handleClose={handleClose}
          handleSubmit={handleFormSubmit}
          Content={
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                name="textFieldInput"
                label="Folder Name"
                type="text"
                fullWidth
                variant="outlined"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              {showError && !inputValue && <span className={classes.errorCls}>Required*</span>}
            </div>
          }
          disableBtn={disableBtn}
        />
      </div>
    </>
  );
};

export default React.memo(SaveFolderName);
