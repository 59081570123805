import React from 'react';
import { Pagination, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import CustomSelect from '../select';

const GetDropDown = ({ dropdownProps }) => {
  const { value, options, callbackFn, label, placeholder } = dropdownProps;
  return (
    <CustomSelect
      value={value}
      options={options}
      handleChange={(value) => callbackFn(value)}
      label={label}
      placeholder={placeholder}
    />
  );
};

const useStyles = makeStyles(() => ({
  pagination: {
    padding: '0px'
  },
  componentFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // width: '100%',
    height: '22px',
    // marginTop: '8px',
    border: '1px solid rgba(229, 229, 229, 1)',
    padding: '12px 0',
    background: '#F7F9FD'
  },
  spanCls: {
    color: '#171717',
    'font-family': 'Satoshi',
    'font-size': '14px',
    'font-style': 'normal',
    'font-weight': 500,
    'line-height': 'normal'
  }
}));

const TablePagination = ({
  loading,
  updatedTotalPages,
  pageNumber,
  setPageNumber,
  pageSizeProps,
  xSmallScreen,
  metadata
}) => {
  const classes = useStyles();
  const { page_number = 0, page_size = 0, total_records = 0 } = metadata || {};
  const currentPageRange = `${(page_number - 1) * page_size + 1} - ${Math.min(
    page_number * page_size,
    total_records
  )}`;
  const totalRecords = total_records;
  return (
    <div
      className={classes.componentFooter}
      style={{
        opacity: loading ? 0.4 : 1,
        pointerEvents: loading ? 'none' : 'auto'
      }}
    >
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ padding: '6px 10px 10px 15px' }} className={classes.spanCls}>
            Showing {currentPageRange} of {totalRecords}
          </span>
          <Pagination
            className={classes.pagination}
            shape="rounded"
            count={updatedTotalPages}
            onChange={(e, value) => setPageNumber(value)}
            size={xSmallScreen ? 'small' : 'medium'}
            page={pageNumber}
          />
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <Typography
            style={{
              fontFamily: 'Satoshi',
              fontSize: '14px',
              color: '#171717',
              fontWeight: '500',
              paddingRight: '10px'
            }}
          >
            Per page
          </Typography>
        </div>
        <div style={{ width: '55px', paddingRight: '15px' }}>
          <GetDropDown dropdownProps={pageSizeProps} />
        </div>
      </div>
    </div>
  );
};

export default TablePagination;
