import {
  SETINTIALSTATESCREENER,
  SETSCREENERTABS,
  SETACTIVESCREENERTAB,
  SETTOGGLESCREANING,
  SETSCREENERDATA,
  SETTABLEDATAERRORMESSAGE
} from '../../action.types';

export const setIntialStateScreener = (data) => {
  return {
    type: SETINTIALSTATESCREENER,
    data
  };
};

export const setScreenerTabs = (data) => {
  return {
    type: SETSCREENERTABS,
    data
  };
};

export const setActiveScreenerTab = (data) => {
  return {
    type: SETACTIVESCREENERTAB,
    data
  };
};

export const setToggleScreening = (data) => {
  return {
    type: SETTOGGLESCREANING,
    data
  };
};

export const setScreenerData = (data) => {
  return {
    type: SETSCREENERDATA,
    data
  };
};

export const setTableDataErrorMessage = (data) => {
  return {
    type: SETTABLEDATAERRORMESSAGE,
    data
  };
};
