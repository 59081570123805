import {
  SETINTIALSTATEFOLDERDC,
  SETFOLDERDATADC,
  SETDOCEXPLORERLOADINGDC,
  SETGLOBALLOADINGDC,
  SETSELECTEDDOCCOMPARE
} from '../../action.types';

const INITIAL_STATE = {
  folderDataDc: [],
  docExplorerloadingDc: false,
  globalLoadingDc: false,
  selectedDocCompare: []
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETFOLDERDATADC:
      return {
        ...state,
        folderDataDc: action.data
      };

    case SETDOCEXPLORERLOADINGDC:
      return {
        ...state,
        docExplorerloadingDc: action.data
      };
    case SETGLOBALLOADINGDC:
      return {
        ...state,
        globalLoadingDc: action.data
      };
    case SETSELECTEDDOCCOMPARE:
      return {
        ...state,
        selectedDocCompare: action.data
      };
    case SETINTIALSTATEFOLDERDC:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
