import React from 'react';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as SortDirectionUp } from '../../../components/icons/SortDirectionUp.svg';
import { ReactComponent as SortDirectionDown } from '../../../components/icons/SortDirectionDown.svg';
import { ReactComponent as SortDirectionUpAndDown } from '../../../components/icons/SortDirectionUpAndDown.svg';
import TablePagination from '../pagination';

const useStyles = makeStyles({
  table: {
    '& th': {
      fontWeight: '700',
      fontFamily: 'Satoshi',
      border: '1px solid rgba(229, 229, 229, 1)',
      background: 'rgba(233, 233, 233, 1)',
      position: 'sticky'
    },
    '& td': {
      fontFamily: 'Satoshi',
      border: '1px solid rgba(229, 229, 229, 1)',
      position: 'sticky'
    }
  },
  tableHeadCell: {
    padding: '10px'
  },
  skeleton: {
    width: '100%',
    height: '53px!important'
  },
  rowCell: {
    padding: '8px'
  }
});

const SortArrow = ({ columnName, sortState }) => {
  const columnData = sortState?.find((item) => item.columnName === columnName);
  const sortOrder = columnData?.order;

  return sortOrder === 'Desc' ? (
    <SortDirectionDown style={{ padding: '0px 3px' }} />
  ) : sortOrder === 'Asc' ? (
    <SortDirectionUp style={{ padding: '0px 6px' }} />
  ) : (
    <SortDirectionUpAndDown style={{ padding: '0px 6px' }} />
  );
};

const TableComp = ({
  tableData = [],
  columnHeaders = [],
  loading = null,
  emptyState = null,
  sortState = [],
  paginationObj = null
  // rowComponents = []
}) => {
  const classes = useStyles();

  // const { page_number = 0, page_size = 0, total_records = 0 } = metadata || {};
  // const totalPages = Math.ceil(total_records / page_size);
  // const currentPageRange = Math.min(page_number * page_size, total_records);
  // const totalRecords = total_records;

  // const totalPages = Math.ceil(total_records / page_size);
  // const currentPageRange = `${(page_number - 1) * page_size + 1} - ${Math.min(
  //   page_number * page_size,
  //   total_records
  // )}`;
  // const totalRecords = total_records;

  return (
    <>
      <TableContainer>
        <Table className={classes.table}>
          {/* Render Table Headers */}
          {/* {loading && (
            <TableHead>
              <TableRow>
                {Array.from({ length: 5 }).map((_, index) => (
                  <TableCell key={index} className={`${classes.tableHeadCell}`}>
                    <Skeleton animation="wave" className={classes.skeleton} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )} */}
          {!loading && columnHeaders.length > 0 && (
            <TableHead>
              <TableRow>
                {columnHeaders.map(({ key, displayName, styleClass, sort }) => (
                  <TableCell
                    key={key}
                    className={`${classes.tableHeadCell} ${styleClass || ''}`}
                    onClick={() => sort && sort(key)}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {displayName}
                      {sort && <SortArrow columnName={key} sortState={sortState} />}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}

          {/* Render Table Body */}
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4}>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <Skeleton key={index} animation="wave" className={classes.skeleton} />
                  ))}
                </TableCell>
              </TableRow>
            ) : tableData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={emptyState.colCnt}>
                  {emptyState.component ? <emptyState.component /> : 'No data available'}
                </TableCell>
              </TableRow>
            ) : (
              tableData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row &&
                    Object.keys(row).length > 0 &&
                    Object.keys(row).map((key, rIn) => {
                      return (
                        <TableCell key={rIn} className={`${classes.rowCell}`}>
                          {row[key]}
                        </TableCell>
                      );
                    })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && paginationObj && <TablePagination {...paginationObj} />}
    </>
  );
};

export default TableComp;
