import React, { useRef, useEffect, useState } from 'react';
import ChipComponent from '../../../../atoms/chipComponent';
import useStyles from '../styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import { Grid, useMediaQuery } from '@mui/material';
import Paper from '@material-ui/core/Paper';
import { LinearProgress } from '@mui/material';
import UserComponent from './userComponent';
// import BotComponent from './botComponent';
import BotComponent from './botComponent';
import FollowUpComponent from './followUp';
import Disclaimer from '../../../askBot/disclaimer';
import { setChatScrollDE, setChatSearchInputDE, setDocCompareStart } from '../../../action';
import { getRandomStrings, printContent } from '../../../../../helpers';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';

const Chat = ({ handleQuestionClick, checkIFqueryRunning, handleStopResponse }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [actveAutoScroll, setAutoScroll] = useState(false);
  const allMessages = useSelector((state) => state.tokenReducer.allMessagesDE);
  const loading = useSelector((state) => state.tokenReducer.loadingDE);
  const chatScroll = useSelector((state) => state.tokenReducer.chatScrollDE);
  const deTags = useSelector((state) => state.tokenReducer.deTags);
  const selectedDocExp = useSelector((state) => state.docCompareReducer.selectedDocCompare);
  const suggestedDeQuestions = []; // useSelector((state) => state.tokenReducer.suggestedDeQuestions);
  // const loadingAnswerStreamDE = useSelector((state) => state.tokenReducer.loadingAnswerStreamDE);
  const stopResponse = useSelector((state) => state.tokenReducer.stopResponseDE);
  const docCompareStart = useSelector((state) => state.tokenReducer.docCompareStart);

  const title = selectedDocExp && selectedDocExp.length > 0 ? '' : 'Please select document.';
  console.log(chatScroll);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileLandscape = useMediaQuery('(max-width: 900px) and (orientation:landscape)');
  const messagesEndRef = useRef(null);
  const prevScrollTop = useRef(0);
  const dispatch = useDispatch();

  const handlePrint = (key) => {
    if (checkIFqueryRunning()) {
      return;
    }
    const contentId = `summaryMain_${key}`;
    const title = 'Private Blok';
    const additionalStyles = `
      .print-icon {
        display: none !important;
      },
      .followUpMain {
          display: none !important;
      }
    `;
    const content = document.getElementById(contentId)?.innerHTML;
    printContent(content, title, additionalStyles);
  };

  const handleScroll = () => {
    if (messagesEndRef.current) {
      const scrollTop = messagesEndRef.current.scrollTop;

      const clientHeight = messagesEndRef.current.clientHeight;
      const scrollHeight = messagesEndRef.current.scrollHeight;

      const bufferHeight = Math.round(clientHeight * 0.1);
      const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - bufferHeight;
      if (scrollTop > prevScrollTop.current && isScrolledToBottom) {
        dispatch(setChatScrollDE(true));
      } else if (scrollTop < prevScrollTop.current) {
        dispatch(setChatScrollDE(false));
      }
      prevScrollTop.current = scrollTop;
    }
  };
  console.log(handleScroll);
  const autoScroll = () => {
    // if (messagesEndRef.current && chatScroll) {
    //   messagesEndRef.current.scrollTo({
    //     top: messagesEndRef.current.scrollHeight,
    //     left: 0,
    //     behavior: 'smooth'
    //   });
    // }

    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTo({
        top: messagesEndRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  // useEffect(() => {
  //   autoScroll();
  // }, [allMessages]);

  console.log(autoScroll);

  useEffect(() => {
    if (docCompareStart) {
      setAutoScroll(true);
      dispatch(setDocCompareStart(false));
    }
  }, [docCompareStart]);

  useEffect(() => {
    const summaryDivs = document.querySelectorAll('[id^="summaryMain_"]');
    if (actveAutoScroll && summaryDivs.length > 0) {
      const lastSummaryDiv = summaryDivs[summaryDivs.length - 1];
      const parentDiv = document.querySelector('.divChatContainer');
      const targetChild = lastSummaryDiv.querySelector('#askBotChildMessageEndRef');

      if (parentDiv && targetChild) {
        // const targetOffsetTop = targetChild.offsetTop;
        const parentRect = parentDiv.getBoundingClientRect();
        // const targetRect = targetChild.getBoundingClientRect();
        const targetOffsetTop = targetChild.offsetTop - parentRect.top;

        parentDiv.scrollTo({
          top: targetOffsetTop - 235,
          behavior: 'smooth'
        });
      }
    }
  }, [allMessages]);
  console.log(autoScroll);

  const handleCardQuestionClick = (event, question) => {
    event.preventDefault();
    if (checkIFqueryRunning()) {
      return;
    }
    setAutoScroll(true);
    dispatch(setChatSearchInputDE(question));
    handleQuestionClick(event, question);
  };

  const isDocIds = selectedDocExp && selectedDocExp.length > 0; // && !suggestedDeQuestions.loading;
  return (
    <>
      <div
        className={`${classes.chatContainer} divChatContainer`}
        ref={messagesEndRef}
        onScroll={() => {
          setAutoScroll(false);
        }}
      >
        <div className={classes.chatMain} style={{ display: 'none' }}>
          <div>
            <div className={classes.headingText}>Help me create</div>
            <div className={classes.flexRow}>
              <ChipComponent
                title={title}
                label="Summary"
                onClick={(e) => {
                  setAutoScroll(true);
                  handleQuestionClick(e, 'Summary');
                }}
                className={classes.chipLabel}
                disabled={!isDocIds}
              />
              <ChipComponent
                title={title}
                label="Key Insights"
                onClick={(e) => {
                  setAutoScroll(true);
                  handleQuestionClick(e, 'Key Insights');
                }}
                className={classes.chipLabel}
                disabled={!isDocIds}
              />
            </div>
          </div>
          <div style={{ paddingLeft: '70px' }}>
            {deTags && deTags.length > 0 && <div className={classes.headingText}>Tags</div>}
            <div className={classes.flexRowTags}>
              {deTags &&
                deTags.length > 0 &&
                deTags.map((x, i) => {
                  return (
                    <ChipComponent
                      key={i}
                      label={x?.entity_name}
                      onClick={() => {}}
                      className={`${classes.defaultCursor} ${classes.chipLabel} ${classes.ovverrideChipLbl}`}
                      disabled={!isDocIds}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className={classes.suggestedMain} style={{ padding: '16px' }}>
          {allMessages.length === 0 ? (
            <div>
              <FollowUpComponent
                title={title}
                loading={suggestedDeQuestions.loading}
                messageData={getRandomStrings(suggestedDeQuestions.questions, 5)}
                matches={matches}
                handleCardQuestionClick={handleCardQuestionClick}
                disabled={!isDocIds}
              />
            </div>
          ) : (
            <>
              <Grid id="askBotItemContianerMain">
                <div id="askBotItemContianer">
                  {/* <Grid item xs={0} md={0.3} id="askBotEmpty" /> */}
                  <div
                    id="askBotChildMainItem"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Grid container item xs={12} id="askBotChildItem" md={11}>
                      <Grid
                        container
                        sx={{
                          height: '100%',
                          paddingTop: matches ? '0px' : '20px'
                        }}
                        id="askBotChildItemContainer"
                      >
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="center"
                          style={{ padding: '5px 0px 5px 0px' }}
                        ></Grid>
                        {allMessages.length === 0 ? (
                          <>
                            <Grid
                              container
                              id="askBotMainMessageContainer"
                              style={{
                                paddingTop: matches ? '0px' : '5px',
                                overflowY: 'auto',
                                height: isMobileLandscape
                                  ? 'calc(100vh - 107px)'
                                  : 'calc(100vh - 360px)'
                              }}
                            ></Grid>
                          </>
                        ) : (
                          <>
                            <Paper
                              id="askBotMainPaper"
                              elevation={0}
                              style={{
                                width: '100%',
                                height: isMobileLandscape
                                  ? 'calc(100vh - 161px)'
                                  : 'calc(100vh - 383px)'
                              }}
                            >
                              <div
                                style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                                id="askBotMainMessageEndRef"
                              >
                                <div
                                  id="askBotChildMessageEndRef"
                                  // ref={messagesEndRef}
                                  // onScroll={() => { }} // handleScroll
                                  // style={{ flex: 1, overflowY: 'auto', paddingTop: '20px' }}
                                >
                                  {Object.keys(allMessages).map(
                                    (key, answer, follow_up_q, disclaimer) => {
                                      if (allMessages[key].creditsExpired) {
                                        return (
                                          <div
                                            key={key}
                                            id={`summaryMain_${key}`}
                                            className={classes.mgBt}
                                          >
                                            <UserComponent
                                              key={key}
                                              chatKey={key}
                                              messageData={allMessages[key]}
                                              handlePrint={handlePrint}
                                            />
                                            {/* <CreditsExpiredComponent
                                      key={key}
                                      messageData={allMessages[key]}
                                    /> */}
                                          </div>
                                        );
                                      } else if (
                                        (allMessages[key].follow_up_q &&
                                          allMessages[key].follow_up_q.length > 0) ||
                                        allMessages[key].disclaimer
                                      ) {
                                        return (
                                          <div
                                            key={key}
                                            id={`summaryMain_${key}`}
                                            className={classes.mgBt}
                                          >
                                            <UserComponent
                                              key={key}
                                              chatKey={key}
                                              messageData={allMessages[key]}
                                              handlePrint={handlePrint}
                                            />
                                            <BotComponent
                                              key={answer}
                                              messageData={allMessages[key]}
                                              showMore={false}
                                              isDEChat={true}
                                            />
                                            <Disclaimer
                                              key={disclaimer}
                                              messageData={allMessages[key]}
                                              matches={matches}
                                            />
                                            <FollowUpComponent
                                              key={follow_up_q}
                                              messageData={[]} // allMessages[key]?.follow_up_q
                                              matches={matches}
                                              handleCardQuestionClick={handleCardQuestionClick}
                                              disabled={!isDocIds}
                                            />
                                          </div>
                                        );
                                      } else if (allMessages[key].answer) {
                                        return (
                                          <div
                                            key={key}
                                            id={`summaryMain_${key}`}
                                            className={classes.mgBt}
                                          >
                                            <UserComponent
                                              key={key}
                                              chatKey={key}
                                              messageData={allMessages[key]}
                                              handlePrint={handlePrint}
                                            />
                                            <BotComponent
                                              key={answer}
                                              messageData={allMessages[key]}
                                              showMore={false}
                                            />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div
                                            key={key}
                                            id={`summaryMain_${key}`}
                                            className={classes.mgBt}
                                          >
                                            <UserComponent
                                              key={key}
                                              chatKey={key}
                                              messageData={allMessages[key]}
                                              handlePrint={handlePrint}
                                            />
                                          </div>
                                        );
                                      }
                                    }
                                  )}
                                  {loading && (
                                    <>
                                      <LinearProgress />
                                      <br />
                                    </>
                                  )}
                                </div>
                              </div>
                            </Paper>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <Grid item xs={0} md={0.3} id="askbotGridEmptyChild" />
                </div>
              </Grid>
            </>
          )}
        </div>
      </div>
      <Grid item container justifyContent="center" id="askbotGridChild">
        <Grid
          id="askbotGridChildItemContainer"
          item
          xs={12}
          md={10}
          container
          justifyContent="center"
          sx={{ height: '100%' }}
        >
          <Grid item xs={0} md={0.3} id="askbotGridChildItemContainerEmpty" />
          <Grid item xs={12} md={11.4} id="askbotGridChildItemContainerRegRes">
            {stopResponse && (
              <div
                style={{ display: 'flex', justifyContent: 'center' }}
                id="askbotGridChildItemContainerStpRes"
              >
                <Button
                  id="askbotGridChildItemContainerStpResButton"
                  style={{
                    textTransform: 'none',
                    backgroundColor: '#FAFAFA',
                    borderRadius: '8px',
                    border: '1px solid #E8E8E8',
                    height: '30px'
                  }}
                  onClick={() => {
                    handleStopResponse();
                  }}
                >
                  <Typography
                    id="askbotGridChildItemContainerStpResTypo"
                    style={{
                      color: '#1D1D1D',
                      fontSize: '14px'
                    }}
                  >
                    {t('askBot.stopBtn')}
                  </Typography>
                </Button>
              </div>
            )}
          </Grid>
          <Grid item xs={0} md={0.3} id="askbotGridFormMainChEmptyGrid" />
        </Grid>
      </Grid>
      <div className={classes.pdBt10}>
        <span className={classes.helpingText}>
          *Answers are for informational purposes only and are not financial advice or opinions.
          Please conduct your own due diligence before making any investment decisions.
        </span>
      </div>
    </>
  );
};

export default Chat;
