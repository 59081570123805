import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from '../styles';
import { Button, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  setActiveScreenerTab,
  setToggleScreening,
  setScreenerData
} from '../../../redux/pages/screener/action';
import { ReactComponent as SeeMoreGray } from '../../../components/icons/SeeMoreGray.svg';
import { ReactComponent as SeeLessGray } from '../../../components/icons/SeeLessGray.svg';

const ScreenerTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const screenerTabs = useSelector((state) => state.screenerReducer.screenerTabs);
  const selectedScreenerTab = useSelector((state) => state.screenerReducer.selectedScreenerTab);
  const toggleScreening = useSelector((state) => state.screenerReducer.toggleScreening);
  const screenerData = useSelector((state) => state.screenerReducer.screenerData);

  const handleScreenerTypeChange = (newFilter) => {
    if (newFilter) {
      dispatch(setActiveScreenerTab(newFilter));
      const updatedScreenerData = screenerData.map((screener) => ({
        ...screener,
        active: screener.entityType === newFilter
      }));
      dispatch(setScreenerData(updatedScreenerData));
    }
  };

  const handleToggleScreening = () => {
    dispatch(setToggleScreening(!toggleScreening));
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '6px 0px 14px 0px',
        alignItems: 'center'
      }}
    >
      <Box sx={{ display: 'flex', gap: '20px' }}>
        {screenerTabs.map((tab) => (
          <Button
            key={tab.key}
            className={selectedScreenerTab === tab.key ? classes.selected : classes.unSelected}
            onClick={() => handleScreenerTypeChange(tab.key)}
            disableRipple
          >
            <Typography
              className={
                selectedScreenerTab === tab.key ? classes.selectedText : classes.unSelectedText
              }
            >
              {tab.value}
            </Typography>
          </Button>
        ))}
      </Box>
      <Button
        onClick={() => handleToggleScreening()}
        disableRipple
        className={classes.tglScreenerBtn}
      >
        <Typography className={classes.tglScreenerTxt}> Screening Criteria </Typography>
        {toggleScreening ? (
          <SeeMoreGray style={{ paddingRight: '7px' }} />
        ) : (
          <SeeLessGray style={{ paddingRight: '7px' }} />
        )}
      </Button>
    </div>
  );
};

export default ScreenerTab;
