import React from 'react';
import { postApi } from '../../../../../services/token';
import DeleteDialog from '../../../../atoms/delete-dialog';
import { useDispatch } from 'react-redux';
import { setFolderDataDc } from '../../../../../redux/pages/docCompare/action';
import { removeFolderById } from '../helper';
import { dispatchHideShowMessage } from '../../../../../helpers';
import { setGlobalErrorMessage, setGlobalLoader, setGlobalSuccessMessage } from '../../../action';

const ingestionUrl = process.env.REACT_APP_INGESTION_API_URL;
const FolderDeleteDialog = ({
  openDeletePopUp,
  setOpenDeletePopUp,
  parentFolderId,
  folderData
}) => {
  const dispatch = useDispatch();

  const handleCloseDeletePopUp = () => {
    setOpenDeletePopUp(false);
  };

  const handleDelete = async () => {
    const folderId = parentFolderId;
    if (folderId) {
      // showErrorMessage('');
      // setLoading(true);
      try {
        dispatch(setGlobalLoader(true));
        await postApi(`${ingestionUrl}/v1/documents`, {
          doc_ids: [],
          folder_ids: [folderId]
        });
        const newFolderData = removeFolderById(folderData, parentFolderId);
        dispatch(setFolderDataDc(newFolderData));
        handleCloseDeletePopUp();
        dispatch(setGlobalLoader(false));
        dispatchHideShowMessage(
          dispatch,
          'Folder deleted successfully.',
          setGlobalSuccessMessage,
          3000
        );
        // showSuccessMessage(t('docLib.deleteSuccess'));
      } catch (err) {
        dispatch(setGlobalLoader(false));
        dispatchHideShowMessage(
          dispatch,
          err?.response?.data?.error?.detail || 'Something went wrong',
          setGlobalErrorMessage,
          3000
        );
        console.log(err);
        // showErrorMessage(t('docLib.deleteError'));
      }
      // setLoading(false);
    }
  };
  return (
    <>
      <DeleteDialog
        openState={openDeletePopUp}
        handleClose={handleCloseDeletePopUp}
        text="Folder"
        content="Are you sure you want to delete this folder. This will be deleted permanently."
        trigger={handleDelete}
      />
    </>
  );
};

export default FolderDeleteDialog;
