import { useSelector } from 'react-redux';
import CustomTooltip from '../../../atoms/customTooltip';

const ToolTip = ({ placement = 'top-start', arrow = true, children }) => {
  const selectedDocExp = useSelector((state) => state.docCompareReducer.selectedDocCompare);
  const title = selectedDocExp && selectedDocExp.length > 0 ? '' : 'Please select document.';
  return (
    <CustomTooltip title={title} placement={placement} arrow={arrow}>
      {children}
    </CustomTooltip>
  );
};

export default ToolTip;
