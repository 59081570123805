import {
  SETINTIALSTATEFOLDERDC,
  SETFOLDERDATADC,
  SETDOCEXPLORERLOADINGDC,
  SETGLOBALLOADINGDC,
  SETSELECTEDDOCCOMPARE
} from '../../action.types';

export const setIntialStateFolderDc = (data) => {
  return {
    type: SETINTIALSTATEFOLDERDC,
    data
  };
};

export const setFolderDataDc = (data) => {
  return {
    type: SETFOLDERDATADC,
    data
  };
};

export const setDocexplorerLoadingDc = (data) => {
  return {
    type: SETDOCEXPLORERLOADINGDC,
    data
  };
};

export const setGlobalLoadingDc = (data) => {
  return {
    type: SETGLOBALLOADINGDC,
    data
  };
};

export const setSelectedDocCompare = (data) => {
  return {
    type: SETSELECTEDDOCCOMPARE,
    data
  };
};
