import React from 'react';
import FilterBox from './filterBox';
import { useSelector } from 'react-redux';

const ScreenerMainSearch = () => {
  const toggleScreening = useSelector((state) => state.screenerReducer.toggleScreening);

  return <>{toggleScreening && <FilterBox />}</>;
};

export default ScreenerMainSearch;
