// import { LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
// import { getApi, postApi, putApi } from '../../../services/token';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import TearSheetSearchBar from './tearSheetSearchBar';
import { updateParamsInURL, hideShowMessage } from '../../../helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import TearSheetMainPageV1 from '../tearSheet-V1/tearSheetMainPage';
import { getApi } from '../../../services/token';
import TearSheet from './report/tearSheet';
import { LinearProgress } from '@mui/material';
import ErrorToast from '../../atoms/errorToast';
import SuccessToast from '../../atoms/successToast';
import CreateReport from './report/create';
import EditReport from './report/edit';
import TemplateLibrary from './report/templateLibrary';
import DeleteReport from './report/deleteReport';
import Limits from './report/limits';
// redux s
import { setTearsheetUrl } from '../action';
import { useDispatch, useSelector } from 'react-redux';
// redux e

const isTearSheetEnabled = process.env.REACT_APP_ENABLE_TEARSHEET === 'true';
const COMPANY_API_URL = process.env.REACT_APP_COMPANY_API;
const PAYMENT_API_URL = process.env.REACT_APP_PAYMENT_API_URL;

const useStyles = makeStyles((theme) => ({
  tearSheetDiv: {
    padding: '0px 20px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 15px 15px'
    }
  },
  maxWidthDiv: {
    maxWidth: '1440px',
    margin: '0px auto',
    position: 'relative'
  },
  '@media print': {
    tearSheetDiv: {
      visibility: 'visible',
      position: 'absolute',
      left: 0,
      top: '-10px',
      padding: '10px!important',
      'margin-top': '73px'
    }
    // mainDiv: {
    //   visibility: 'hidden'
    // }
  },
  mainDiv: {
    padding: '10px 40px 16px',
    gap: '56px'
  },
  reportDataCls: {
    padding: '20px 40px'
  },
  minHeight: {
    minHeight: 'calc(100vh - 138px)'
  },
  fontCFamilyStyle: {
    fontFamily: 'Satoshi',
    fontStyle: 'normal'
  },
  customText: {
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '30px',
    margin: 0
  },
  gradientText: {
    'background-clip': ' text !important',
    'background-image':
      'linear-gradient(to right, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%) !important',
    '-webkit-text-fill-color': 'transparent !important'
  },
  wt100: {
    width: '100%'
  },
  pointer: {
    cursor: 'pointer !important'
  },
  whiteBg: {
    background: '#fff!important'
  },
  toggleButton: {
    fontSize: '16px!important',
    'font-weight': 700,
    'line-height': '24px!important',
    'letter-spacing': '0.5px!important',
    '&.Mui-selected': {
      background:
        'transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box',
      color: 'white!important'
    },
    '&.MuiToggleButton-root': {
      border: '1px solid var(--Border-Colors-Border3, #E7E7E7)!important',
      textTransform: 'none',
      padding: '6px 16px',
      '&:not(:last-child)': {
        marginRight: '20px'
      },
      'border-radius': '4px !important',
      gap: '20px'
    }
  },
  mBtPdTop: {
    marginBottom: '20px',
    'padding-top': '10px'
  }
}));

const CreateEditRenderPage = ({ current_page, searchParams, loadingData }) => {
  if (current_page === 'create')
    return <CreateReport searchParams={searchParams} loadingData={loadingData} />;
  if (current_page === 'edit')
    return <EditReport searchParams={searchParams} loadingData={loadingData} />;
  if (current_page === 'template-library')
    return <TemplateLibrary searchParams={searchParams} loadingData={loadingData} />;
  return null;
};

const TearSheetPage = ({ currentRoute, searchParams, loadingData, getLimits }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openReportDelete, setOpenReportDelete] = useState(false);

  // redux s
  const storedtearsheetUrl = useSelector((state) => state.tokenReducer.tearsheetUrl);
  const dispatch = useDispatch();
  // redux e

  const classes = useStyles();
  const entity_type = searchParams.get('entity_type') || 'company';
  const report_id_param = searchParams.get('report_id');

  const { t } = useTranslation();

  // redux s
  useEffect(() => {
    if (storedtearsheetUrl && storedtearsheetUrl !== `${location.pathname}${location.search}`) {
      navigate(storedtearsheetUrl);
    }
  }, [storedtearsheetUrl]);

  useEffect(() => {
    updateTearsheetUrl();
  }, [location.pathname, location.search]);

  const updateTearsheetUrl = () => {
    const fullUrl = `${location.pathname}${location.search}`;
    dispatch(setTearsheetUrl(fullUrl));
  };
  // redux e

  const { loading, setLoading, successMessage, setSuccessMessage, errorMessage, setErrorMessage } =
    loadingData;

  const [selectedFilter, setSelectedFilter] = useState(entity_type || 'company');
  const [reportSummary, setReportSummary] = useState(null);

  const [currentEntityId, setCurrentEntityId] = useState('');
  const [currentReportId, setCurrentReportId] = useState('');
  const [reportName, setReportName] = useState('');

  useEffect(() => {
    const newEntityType = searchParams.get('entity_type') || 'company';
    setSelectedFilter(newEntityType);
  }, [searchParams]);

  const handleFilterChange = (event, newFilter) => {
    if (newFilter) {
      setSelectedFilter(newFilter);
      updateParamsInURL({
        navigate,
        location,
        addParams: { entity_type: newFilter },
        isNew: true
      });
    }
  };

  const [currentTimeout, setCurrentTimeout] = useState(null);

  const checkAndClearTimeout = () => {
    if (currentTimeout) {
      clearTimeout(currentTimeout);
      setCurrentTimeout(null);
    }
  };

  useEffect(() => {
    if (!report_id_param) {
      checkAndClearTimeout();
    }
  }, [report_id_param]);

  const getSummaryData = async (selectedEntity, report_id, showLoader = true) => {
    if (currentTimeout) {
      checkAndClearTimeout();
    }
    if (report_id && selectedFilter && selectedEntity?.entity_id) {
      try {
        if (showLoader) setLoading(true);
        const { data } = await getApi(
          `${COMPANY_API_URL}/v2/report/${report_id}?entity_id=${selectedEntity?.entity_id}&entity_type=${selectedFilter}`
        );
        setReportSummary(data?.data);
        const status = data?.data?.metadata?.report_status;

        if (status && (status === t('tearSheet.inProgress') || status === t('tearSheet.inQueue'))) {
          const timeoutId = setTimeout(() => {
            getSummaryData(selectedEntity, report_id, false);
          }, 10000);
          setCurrentTimeout(timeoutId);
        }
      } catch (error) {
        hideShowMessage(
          'Error occurred while processing report data. Please try again.',
          setErrorMessage
        );
      } finally {
        setLoading(false);
      }
    } else {
      setReportSummary(null);
    }
  };

  const resetSummary = () => {
    setReportSummary(null);
  };

  const onSelectReportId = (selectedEntity, report_id) => {
    getSummaryData(selectedEntity, report_id);
  };

  const handleReportDeleteOpen = (entity_id, report_id, report_name) => {
    if (entity_id && report_id && report_name) {
      setCurrentEntityId(entity_id);
      setCurrentReportId(report_id);
      setReportName(report_name);
      setOpenReportDelete(true);
    }
  };

  const handleReportDeleteClose = () => {
    setCurrentEntityId('');
    setCurrentReportId('');
    setReportName('');
    setOpenReportDelete(false);
  };

  const tearSheetSearchBarProps = {
    t,
    selectedFilter,
    location,
    navigate,
    updateParamsInURL,
    searchParams,
    onSelectReportId,
    COMPANY_API_URL,
    resetSummary,
    loading,
    setLoading,
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    handleReportDeleteOpen,
    getLimits
  };

  return (
    <>
      {isTearSheetEnabled ? (
        <>
          <DeleteReport
            reportName={reportName}
            open={openReportDelete}
            handleClose={handleReportDeleteClose}
            entityType={entity_type}
            entityId={currentEntityId}
            reportId={currentReportId}
            setErrorMessage={setErrorMessage}
            setLoading={setLoading}
          />
          <div className={classes.minHeight}>
            <div style={{ background: '#F7F9FD' }}>
              <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
                <div className={classes.mainDiv}>
                  <p
                    className={`${classes.wt100} ${classes.customText}
               ${classes.fontCFamilyStyle}
               `}
                  >
                    <span className={classes.gradientText}>Custom Reports</span>
                  </p>
                  <div className={classes.mBtPdTop}>
                    <ToggleButtonGroup
                      value={selectedFilter}
                      exclusive
                      onChange={handleFilterChange}
                      className={classes.pointer}
                    >
                      <ToggleButton
                        value="company"
                        className={`${selectedFilter === 'company' ? '' : classes.whiteBg} ${
                          classes.toggleButton
                        }`}
                      >
                        Company
                      </ToggleButton>
                      <ToggleButton
                        value="theme"
                        className={`${selectedFilter === 'theme' ? '' : classes.whiteBg} ${
                          classes.toggleButton
                        }`}
                      >
                        Theme
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>

                  <TearSheetSearchBar tearSheetSearchBarProps={tearSheetSearchBarProps} />
                </div>
              </div>
            </div>
            <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
              <div className={`${classes.tearSheetDiv} ${classes.reportDataCls}`}>
                {reportSummary && (
                  <TearSheet
                    tearSheetData={reportSummary}
                    getSummaryData={getSummaryData}
                    entity_type={entity_type}
                    setLoading={setLoading}
                    navigate={navigate}
                    location={location}
                    handleReportDeleteOpen={handleReportDeleteOpen}
                    setErrorMessage={setErrorMessage}
                    getLimits={getLimits}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <TearSheetMainPageV1 currentRoute={currentRoute} />
      )}
    </>
  );
};

const TearSheetMainPage = ({ currentRoute }) => {
  const [searchParams] = useSearchParams();
  const action = searchParams.get('action');

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [limitsData, setLimitsData] = useState(null);

  const getLimits = async () => {
    const { data } = await getApi(`${PAYMENT_API_URL}/user/limits`);
    setLimitsData(data?.data);
  };

  useEffect(() => {
    getLimits();
  }, []);

  const loadingData = {
    loading,
    setLoading,
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage
  };

  return (
    <>
      {loading && <LinearProgress />}
      <ErrorToast errorMessage={errorMessage} />
      <SuccessToast successMessage={successMessage} />
      <div style={{ maxWidth: '1440px', margin: '0px auto' }}>
        <CreateEditRenderPage
          current_page={action}
          searchParams={searchParams}
          loadingData={loadingData}
          limitsData={limitsData}
        />
      </div>
      {action !== 'create' && action !== 'edit' && action !== 'template-library' && (
        <TearSheetPage
          getLimits={getLimits}
          limitsData={limitsData}
          currentRoute={currentRoute}
          searchParams={searchParams}
          loadingData={loadingData}
        />
      )}
      {limitsData && <Limits limitsData={limitsData} />}
    </>
  );
};

export default TearSheetMainPage;
