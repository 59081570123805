import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Pagination, Button, Skeleton, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { deleteApi, getApi } from '../../../../services/token';
import UploadDocExpFile from './upload';
import { Dialog, DialogContent } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedDocExp,
  setDeTags,
  setSuggestedDeQuestions,
  setAllMessagesDE,
  setUuID
} from '../../action';
import { removeObjRef } from '../../../../helpers';
import { Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';
import HoverLink from '../../../atoms/hoverLink';
import { ReactComponent as SearchIcon } from '../../../../components/icons/SearchIcon.svg';
import { ReactComponent as RefreshIcon } from '../../../../components/icons/RefreshIcon.svg';
import { ReactComponent as ExceptionIcon } from '../../../icons/ExceptionsIcon.svg';

import { TextField } from '@material-ui/core';
import { ReactComponent as DeleteBinIcon } from '../../../icons/DeleteBinIcon.svg';
import DeleteDialog from '../../../atoms/delete-dialog';
import DocDetailsPopUp from '../../../docLib/manage/docDetailsPopUp';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(() => ({
  mainFileGridContainer: {
    // height: '100%',
    height: 'calc(100vh - 180px)',
    paddingLeft: '20px'
  },
  box: {
    padding: '10px 20px',
    background: `transparent linear-gradient(90deg, #8F34EA1A 0%, #2B4BDA1A 100%) 0% 0% no-repeat padding-box`,
    borderRadius: '8px',
    height: '100%'
  },
  docExplorerMainTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '18px !important',
    fontWeight: '600 !important',
    color: '#373737 !important'
  },
  pagination: {
    padding: '0px'
  },
  borderButtonStyle: {
    marginLeft: 'auto',
    position: 'relative',
    height: '32px',
    width: '80px',
    zIndex: 1,
    background: 'linear-gradient(to right, #004CFF, #06BCC1)',
    border: 'none',
    outline: 'none',
    textTransform: 'none !important',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1.5px',
      right: '1.5px',
      top: '1.5px',
      bottom: '1.5px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  textBtn: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '700 !important',
    color: '#171717 !important',
    padding: '0px 2px'
  },
  noFileTxt: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    color: '#171717 !important',
    opacity: '0.7'
  },
  fileName: {
    fontFamily: 'Satoshi !important',
    fontSize: '13px !important',
    fontWeight: '400 !important',
    lineHeight: '20px',
    lineBreak: 'anywhere',
    'text-decoration': 'none'
  },
  fileNameColor: {
    color: '#373737 !important'
  },
  errorMessage: {
    color: 'red!important'
  },
  root: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E8E8E8',
    borderRadius: '4px',
    '& .MuiInput-underline:before': {
      display: 'none'
    },
    '& .MuiInput-underline:after': {
      display: 'none'
    },
    '& .MuiInput-underline:hover:before': {
      display: 'none'
    },
    '& .MuiInputBase-input': {
      caretColor: '#B0B0B0'
    }
  },
  searchIcon: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    color: '#80a6ff',
    padding: '0px 5px 0px 10px'
  },
  deleteIcon: {
    display: 'none',
    cursor: 'pointer',
    padding: '5px 0px 5px 5px'
  },
  hoverable: {
    '&:hover $deleteIcon': {
      display: 'block'
    }
  },
  exceptionIcon: {
    // width: '16px',
    // height: '16px',
    paddingLeft: '5px',
    cursor: 'pointer'
  }
}));

const isSingleSelection = false; //process.env.REACT_APP_DOC_EXPLORER_SINGLE_SELECTION;
const DocExplorerFilesV1 = ({ checkIFqueryRunning, showErrorMessage, showSuccessMessage }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [exceptionDoc, setExceptionDoc] = useState({});
  const [openUploadState, setOpenUploadState] = useState(false);
  const [allIngestionData, setIngestionData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [input, setInput] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [sortOrder, setSortOrder] = useState('Desc');
  const [sortBy, setSortBy] = useState('updated_at');

  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const [deleteDocumentInfo, setDeleteDocinfo] = useState(null);

  const ingestionUrl = process.env.REACT_APP_INGESTION_API_URL;
  // const baseUrl = process.env.REACT_APP_API_BASE_LB;
  const location = useLocation();

  const dispatch = useDispatch();
  const selectedDocExp = useSelector((state) => state.tokenReducer.selectedDocExp);
  const allMessagesDE = useSelector((state) => state.tokenReducer.allMessagesDE);
  const deTags = useSelector((state) => state.tokenReducer.deTags);

  const [cachedPages, setCachedPages] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('openBox') === 'true') {
      handleClick();
      params.delete('openBox');
      const newUrl = params.toString()
        ? `${location.pathname}?${params.toString()}`
        : location.pathname;
      window.history.replaceState(null, '', newUrl);
    }
  }, [location]);

  const getDocFromDocLib = async (skeletonLoading) => {
    try {
      if (skeletonLoading) {
        setLoading(true);
        setIngestionData([]); // Clear current data
      }
      let queryStr = `page_size=${pageSize}&page_number=${pageNumber}`;
      queryStr += sortBy ? `&sort_by=${sortBy}` : '';
      queryStr += sortOrder ? `&sort_order=${sortOrder}` : '';
      queryStr += `&input_source=doc_explorer`;
      queryStr += input ? `&query=${input}` : ``;
      const ingestionData = await getApi(`${ingestionUrl}/v1/document?${queryStr}`);
      const { data } = ingestionData?.data || {};
      let { items = [], metadata = {} } = data || {};
      const {
        total_records = totalRecords,
        page_number = pageNumber,
        page_size = pageSize
      } = metadata;

      // Handle page adjustment if necessary
      const pageChangeFlag = (page_number - 1) * pageSize >= total_records;
      if (page_number > 1 && pageChangeFlag) {
        setPageNumber(page_number - 1);
        return;
      }

      items = items.filter(
        (item) =>
          item.association_types &&
          Array.isArray(item.association_types) &&
          item.association_types.length
      );

      // Cache the data by page number
      setCachedPages((prevCache) => ({ ...prevCache, [pageNumber]: items }));
      setIngestionData(items);
      setPageNumber(page_number);
      setPageSize(page_size);
      setTotalRecords(total_records);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      if (skeletonLoading) {
        setLoading(false);
      }
    }
  };

  const dataRef = useRef(allIngestionData);

  useEffect(() => {
    dataRef.current = allIngestionData;
  }, [allIngestionData]);

  useEffect(() => {
    const checkLatestFileStats = async () => {
      let currentTime = Date.now();
      const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds

      console.log(dataRef.current);
      dataRef.current.forEach((doc) => {
        if (doc.status === 'INGESTED' || doc.status === 'FAILED') {
          return;
        }

        // const updatedAt = new Date(doc.updated_at).getTime(); // Convert updated_at to Date object and get time in milliseconds
        const updatedAt = new Date(doc.updated_at + ' UTC').getTime();
        const timeDifference = currentTime - updatedAt;
        if (timeDifference < fifteenMinutes) {
          getDocFromDocLib(false);
          return;
        }
      });
    };

    // Set interval for every 10 seconds
    const intervalId = setInterval(checkLatestFileStats, 10000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getDocFromDocLib(true);
  }, [pageNumber, sortOrder, sortBy, pageSize, input]);

  const handleRefresh = () => {
    setCachedPages({});
    setPageNumber(1);
    getDocFromDocLib(true);
  };

  const getInsights = async (updatedDeIds = '') => {
    if (!updatedDeIds) return;
    try {
      const newAllmessagesDE = removeObjRef(allMessagesDE);

      dispatch(setSuggestedDeQuestions({ questions: [], loading: true }));
      let ingestionData = await getApi(
        `${ingestionUrl}/v1/document/insights?doc_ids=${updatedDeIds}`
      );
      if (isSingleSelection) {
        dispatch(setAllMessagesDE([]));
      } else {
        if (newAllmessagesDE && Object.keys(newAllmessagesDE).length > 0) {
          const lastKey = Object.keys(newAllmessagesDE).pop();

          newAllmessagesDE[lastKey].follow_up_q = ingestionData.data || [];
          dispatch(setAllMessagesDE({ ...newAllmessagesDE }));
        }
      }

      const {
        summary,
        ['key insights']: key_insights,
        suggested_questions: questions
      } = ingestionData?.data?.data || {};
      dispatch(
        setSuggestedDeQuestions({
          summary,
          key_insights,
          questions,
          loading: false
        })
      );
    } catch (error) {
      dispatch(
        setSuggestedDeQuestions({ summary: '', key_insights: '', questions: [], loading: false })
      );
    }
  };

  // const getSuggestedQuestions = async (updatedDeIds) => {
  //   try {
  //     const newAllmessagesDE = removeObjRef(allMessagesDE);
  //     dispatch(setSuggestedDeQuestions({ questions: [], loading: true }));
  //     let ingestionData = await postApi(`${baseUrl}/server/suggested-questions`, {
  //       doc_ids: updatedDeIds
  //     });
  //     if (isSingleSelection) {
  //       dispatch(setAllMessagesDE([]));
  //     } else {
  //       if (newAllmessagesDE && Object.keys(newAllmessagesDE).length > 0) {
  //         const lastKey = Object.keys(newAllmessagesDE).pop();

  //         newAllmessagesDE[lastKey].follow_up_q = ingestionData.data || [];
  //         dispatch(setAllMessagesDE({ ...newAllmessagesDE }));
  //       }
  //     }

  //     dispatch(setSuggestedDeQuestions({ questions: ingestionData.data, loading: false }));
  //   } catch (error) {
  //     dispatch(setSuggestedDeQuestions({ questions: [], loading: false }));
  //   }
  // };

  const handleCheckboxChange = async (doc) => {
    if (checkIFqueryRunning()) {
      return;
    }
    let updatedSelectedDocs;

    // Check if the document is already selected
    const isSelected = selectedDocExp.some((d) => d.doc_id === doc.doc_id);

    dispatch(setUuID(uuidv4()));

    if (isSelected) {
      // If it's already selected, remove it from selectedDocs
      updatedSelectedDocs = selectedDocExp.filter((d) => d.doc_id !== doc.doc_id);
    } else {
      if (isSingleSelection) {
        updatedSelectedDocs = [
          {
            doc_id: doc.doc_id,
            file_name: `${doc.file_name}.${doc.file_format}`,
            tags: doc.tags
          }
        ];
      } else {
        const selectionConfig = process.env.REACT_APP_DOCUMENT_SELECTION_DC || 4;
        if (selectedDocExp.length >= selectionConfig) {
          showErrorMessage(
            `You can select a maximum of ${selectionConfig} documents. Please deselect one to choose another.`
          );
          return;
        }
        updatedSelectedDocs = [
          ...selectedDocExp,
          {
            doc_id: doc.doc_id,
            file_name: `${doc.file_name}.${doc.file_format}`,
            tags: doc.tags
          }
        ];
      }
    }

    const updatedDeTags = Array.from(new Set(updatedSelectedDocs.flatMap((d) => d.tags)));

    // Dispatch the updated deTags list
    const updatedDeIds = Array.from(new Set(updatedSelectedDocs.flatMap((d) => d.doc_id)));
    dispatch(setDeTags(updatedDeTags));
    dispatch(setSelectedDocExp(updatedSelectedDocs));
    // await getSuggestedQuestions(updatedDeIds);
    // await getInsights(updatedDeIds.join(','));
    console.log(typeof getInsights, typeof updatedDeIds);
  };

  console.log(setSortOrder);
  console.log(setSortBy);
  console.log('deTags ' + JSON.stringify(deTags));
  const updatedTotalPages = Math.floor((totalRecords - 1) / [pageSize]) + 1;

  const handleClick = () => {
    setOpenUploadState(true);
  };

  const handleClosePopup = () => {
    setOpenUploadState(false);
  };

  const handleUploadPopup = () => {
    setCachedPages({});
    setPageNumber(1);
    getDocFromDocLib(false);
    setOpenUploadState(false);
    handleRefresh();
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);

    // Clear the previous debounce timeout and set a new one
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout to update the input after 3 seconds
    setDebounceTimeout(
      setTimeout(() => {
        setCachedPages({});
        setPageNumber(1);
        setInput(event.target.value);
      }, 500)
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setCachedPages({});
      setPageNumber(1);
      event.preventDefault(); // Prevent form submission
      setInput(inputValue); // Call setInput with the current input value
    }
  };
  const handleDeleteClick = (event, doc) => {
    event.preventDefault();
    handleOpenDeletePopUp();
    setDeleteDocinfo(doc);
  };

  const handleOpenDeletePopUp = () => {
    setOpenDeletePopUp(true);
  };

  const handleCloseDeletePopUp = () => {
    setOpenDeletePopUp(false);
    setDeleteDocinfo(null);
  };

  const handleDelete = async () => {
    const docid = deleteDocumentInfo?.doc_id;
    if (docid) {
      showErrorMessage('');
      setLoading(true);
      try {
        await deleteApi(`${ingestionUrl}/v1/document/${docid}`);
        await getDocFromDocLib(false);
        handleCloseDeletePopUp();
        showSuccessMessage(t('docLib.deleteSuccess'));
      } catch (err) {
        console.log(err);
        showErrorMessage(t('docLib.deleteError'));
      }
      setLoading(false);
    }
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {open && (
        <DocDetailsPopUp
          row={exceptionDoc}
          open={open}
          handleClose={handleClose}
          typeOfTable="subDocFailures"
        />
      )}
      <Dialog open={openUploadState} fullWidth={true} maxWidth="lg">
        <DialogContent style={{ padding: '24px' }}>
          <UploadDocExpFile
            handleClosePopup={handleClosePopup}
            handleUploadPopup={handleUploadPopup}
          />
        </DialogContent>
      </Dialog>
      <DeleteDialog
        openState={openDeletePopUp}
        handleClose={handleCloseDeletePopUp}
        text="File"
        trigger={handleDelete}
      />

      <div className={classes.mainFileGridContainer}>
        <Box className={classes.box}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '6px 0px 20px 0px'
            }}
          >
            <div>
              <Typography className={classes.docExplorerMainTxt}>
                {t('docExplorer.documents')}
              </Typography>
            </div>
            <div>
              <Button className={classes.borderButtonStyle} onClick={() => handleClick()}>
                <Typography className={classes.textBtn}>Upload</Typography>
              </Button>
            </div>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
          >
            <div style={{ height: 'calc(100vh - 280px)', overflowY: 'auto' }}>
              <form noValidate style={{ padding: '0px 10px 10px 0px' }}>
                <div style={{ display: 'flex' }}>
                  <TextField
                    className={classes.root}
                    placeholder={'Search Documents'}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      startAdornment: <SearchIcon className={classes.searchIcon} />
                    }}
                  />
                  <div style={{ paddingLeft: '5px' }} />
                  <RefreshIcon
                    style={{
                      cursor: 'pointer',
                      border: '1px solid #797F8D',
                      borderRadius: '4px',
                      padding: '2.25px 5.5px',
                      height: '25px',
                      background: '#FFFFFF'
                    }}
                    onClick={() => {
                      handleRefresh();
                    }}
                  />
                </div>
              </form>
              {loading === true ? (
                <>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <div key={index}>
                      <Card
                        sx={{
                          maxWidth: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: '#FAFAFA',
                          borderRadius: '8px',
                          border: '1px solid #E8E8E8'
                        }}
                        elevation={0}
                      >
                        <Skeleton animation="wave" height={10} width="20%" style={{ margin: 20 }} />
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="70%"
                          style={{ marginBottom: 10, marginLeft: 20, marginRight: 6 }}
                        />
                      </Card>
                      <br />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {allIngestionData.length === 0 && input !== null && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '200px'
                      }}
                    >
                      <Typography className={classes.noFileTxt}>No Files found</Typography>
                    </div>
                  )}
                  {allIngestionData.map((doc) => (
                    <div key={doc.doc_id}>
                      <label>
                        <div style={{ display: 'flex' }} className={classes.hoverable}>
                          <div style={{ padding: '5px 0px', position: 'relative' }}>
                            <Tooltip
                              title={
                                doc.status === 'FAILED'
                                  ? 'File processing failed'
                                  : doc.status !== 'INGESTED' && 'File is being processed'
                              }
                              arrow
                            >
                              <input
                                type={isSingleSelection ? 'radio' : 'checkbox'}
                                checked={selectedDocExp.some((d) => d.doc_id === doc.doc_id)}
                                onChange={() => handleCheckboxChange(doc)}
                                disabled={doc.status !== 'INGESTED'}
                                name="radio_doc_expo"
                                style={{
                                  backgroundColor: selectedDocExp.some(
                                    (d) => d.doc_id === doc.doc_id
                                  )
                                    ? '#004CFF'
                                    : '#FFFFFF',
                                  height: '18px',
                                  width: '18px',
                                  border: '1px solid #004CFF',
                                  borderRadius: '4px',
                                  cursor: 'pointer'
                                }}
                              />
                            </Tooltip>
                          </div>
                          <div style={{ padding: '5px 0px 10px 5px', display: 'flex' }}>
                            <HoverLink
                              className={`${classes.fileName} ${
                                doc.status === 'FAILED'
                                  ? classes.errorMessage
                                  : classes.fileNameColor
                              }`}
                              href={doc?.source_link}
                              target="_blank"
                              displayText={`${doc.file_name}.${doc.file_format}`}
                              disabled={doc.status === 'FAILED' || doc.status === 'INGESTED'}
                            />
                            {doc.subdoc_ingestion_failure === true && (
                              <Tooltip
                                title={t('statusChip.exceptionToolTipMessage')}
                                placement="top"
                              >
                                <ExceptionIcon
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setExceptionDoc(doc);
                                    handleOpen();
                                  }}
                                  className={classes.exceptionIcon}
                                />
                              </Tooltip>
                            )}
                          </div>
                          <div className={classes.deleteIcon}>
                            <Tooltip title={t('list.deleteTooltip')} placement="top">
                              <DeleteBinIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteClick(e, doc);
                                }}
                                style={{ cursor: 'pointer' }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </label>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div
              style={{
                display:
                  loading === true && Object.keys(cachedPages).length === 0 ? 'none' : 'flex',
                justifyContent: 'center'
              }}
            >
              <Pagination
                className={classes.pagination}
                shape="rounded"
                count={updatedTotalPages}
                onChange={(e, value) => {
                  setPageNumber(value);
                }}
                size={'small'}
                page={pageNumber}
              />
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default DocExplorerFilesV1;
