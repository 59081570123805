import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DocExplorerFiles from './Files';
import DocExplorerFilesV1 from './Files-V1';
import DocExplorerChat from './Chat';
import ErrorToast from '../../atoms/errorToast';
import { useSelector } from 'react-redux';
import { hideShowMessage } from '../../../helpers';
import SuccessToast from '../../atoms/successToast';
import LoadingPopover from '../loadingPopover/loadingPopover';

const useStyles = makeStyles((theme) => ({
  mainGridContainer: {
    padding: '20px 20px 10px 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 80px'
    }
  }
}));

const DocExplorer = () => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const loadingDE = useSelector((state) => state.tokenReducer.loadingDE);
  const loadingAnswerStreamDE = useSelector((state) => state.tokenReducer.loadingAnswerStreamDE);
  const enableFolderStructure = process.env.REACT_APP_ENABLE_DOCEXPLORER_FOLDER === 'true' ?? false;
  const globalLoading = useSelector((state) => state.docExplorerReducer.globalLoading);

  const showErrorMessage = (msg) => {
    hideShowMessage(msg, setErrorMessage, 3000);
  };

  const showSuccessMessage = (msg) => {
    hideShowMessage(msg, setSuccessMessage, 3000);
  };

  const checkIFqueryRunning = () => {
    if (loadingDE || loadingAnswerStreamDE) {
      showErrorMessage('Your query is being processed. Please wait for a response.');
      return true;
    }
    return false;
  };

  return (
    <>
      <LoadingPopover open={globalLoading} />
      <ErrorToast errorMessage={errorMessage} />
      <SuccessToast successMessage={successMessage} />
      <div style={{ minHeight: 'calc(100vh - 120px)' }}>
        <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
          <Grid container className={classes.mainGridContainer}>
            <Grid xs={9}>
              <DocExplorerChat checkIFqueryRunning={checkIFqueryRunning} />
            </Grid>
            <Grid xs={3}>
              {enableFolderStructure ? (
                <DocExplorerFiles
                  showErrorMessage={showErrorMessage}
                  showSuccessMessage={showSuccessMessage}
                  checkIFqueryRunning={checkIFqueryRunning}
                />
              ) : (
                <DocExplorerFilesV1
                  showErrorMessage={showErrorMessage}
                  showSuccessMessage={showSuccessMessage}
                  checkIFqueryRunning={checkIFqueryRunning}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default DocExplorer;
