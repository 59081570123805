import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  titleCls: {
    color: '#171717',
    'font-family': 'Satoshi',
    'font-size': '20px',
    'font-style': 'normal',
    'font-weight': 700,
    'line-height': 'normal'
  },
  submitBtn: {
    display: 'flex',
    width: '585px',
    'justify-content': 'center',
    'align-items': 'flex-start',
    gap: '20px',
    '& button': {
      background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
      textTransform: 'none',
      color: '#ffffff'
    }
  }
}));

const defaultFn = () => {};
const DynamicDialog = ({
  title = '',
  open = false,
  handleClose = defaultFn,
  handleSubmit = defaultFn,
  Content = <></>,
  btnText = 'Save'
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          // const formData = new FormData(event.currentTarget);
          // const formJson = Object.fromEntries(formData.entries());
          // handleSubmit(formJson);
          handleSubmit(event);
        }
      }}
    >
      <DialogTitle>
        <div className={classes.titleContainer}>
          <span className={classes.titleCls}>{title}</span>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>{Content}</DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <div className={classes.submitBtn}>
          <Button type="submit">{btnText}</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DynamicDialog;
