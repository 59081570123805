import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ReactComponent as ThreeDotsSvg } from '../../icons/3dots.svg';

const CustomMenu = ({
  triggerElement,
  menuOptions = [],
  onOptionSelect = () => {},
  setIsMenuOpen = null
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const isFunction = typeof setIsMenuOpen === 'function';

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    if (isFunction) setIsMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (isFunction) setIsMenuOpen(false);
  };

  const handleMenuItemClick = (e, option) => {
    onOptionSelect(option);
    handleClose(e);
  };

  return (
    <div>
      <div onClick={handleOpen} style={{ cursor: 'pointer' }}>
        {triggerElement ? (
          triggerElement
        ) : (
          <ThreeDotsSvg style={{ marginRight: '8px' }} onClick={handleOpen} />
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          style: { padding: 0 }
        }}
      >
        {menuOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={(e) => handleMenuItemClick(e, option)}
            style={{
              padding: '0 0 0 12px',
              'border-bottom': '1px solid #EDEDED',
              height: '42px'
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CustomMenu;
