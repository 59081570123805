import { getApi, putApi, postApi, deleteApi } from '../../../../services/token';

export const getFolderData = async (folder_id = null) => {
  try {
    const url =
      folder_id !== null
        ? `${process.env.REACT_APP_INGESTION_API_URL}/v2/document?folder_id=${folder_id}`
        : `${process.env.REACT_APP_INGESTION_API_URL}/v2/document?input_source=doc_explorer`;

    const folderData = await getApi(url);

    if (!folderData?.data?.data?.items) {
      throw new Error('Something went wrong , please try again later');
    }

    return folderData.data.data.items;
  } catch (error) {
    // Check if backend provided error details and include them in the thrown error
    const backendError =
      error?.response?.data?.error?.detail || 'Something went wrong , please try again later';
    throw new Error(backendError);
  }
};

export const getSearchFileData = async (searchVal) => {
  try {
    const url = `${process.env.REACT_APP_INGESTION_API_URL}/v2/document?input_source=doc_explorer&query=${searchVal}`;
    const folderData = await getApi(url);
    return folderData.data.data.items;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteFolder = async (folder_id = null) => {
  try {
    const url = `${process.env.REACT_APP_INGESTION_API_URL}/v1/document/${folder_id}`;
    const data = await deleteApi(url);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createNewFolder = async (body) => {
  try {
    const url = `${process.env.REACT_APP_INGESTION_API_URL}/v1/folder`;
    const data = await postApi(url, body);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const moveFolder = async (folder_id, body) => {
  try {
    // const url = `${process.env.REACT_APP_INGESTION_API_URL}/v1/folder?folder_id=${folder_id}`;
    const url = `${process.env.REACT_APP_INGESTION_API_URL}/v1/folder/${folder_id}`;
    const data = await putApi(url, body);
    return data;
  } catch (error) {
    const backendError =
      error?.response?.data?.error?.detail ||
      error.message ||
      'Something went wrong while moving the folder. Please try again.';
    throw new Error(backendError);
  }
};

export const moveFile = async (folder_id, body) => {
  try {
    // const url = `${process.env.REACT_APP_INGESTION_API_URL}/v1/folder?folder_id=${folder_id}`;
    const url = `${process.env.REACT_APP_INGESTION_API_URL}/v1/document/${folder_id}`;
    await putApi(url, body);
  } catch (error) {
    const backendError =
      error?.response?.data?.error?.detail ||
      error.message ||
      'Something went wrong while moving the file. Please try again.';
    throw new Error(backendError);
  }
};
