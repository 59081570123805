import {
  SETINTIALSTATEFOLDER,
  SETFOLDERDATA,
  SETDOCEXPLORERLOADING,
  SETGLOBALLOADING,
  SETSELECTEDDOCEXPLORER
} from '../../action.types';

const INITIAL_STATE = {
  folderData: [],
  docExplorerloading: false,
  globalLoading: false,
  selectedDocExplorer: []
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETFOLDERDATA:
      return {
        ...state,
        folderData: action.data
      };

    case SETDOCEXPLORERLOADING:
      return {
        ...state,
        docExplorerloading: action.data
      };
    case SETGLOBALLOADING:
      return {
        ...state,
        globalLoading: action.data
      };
    case SETSELECTEDDOCEXPLORER:
      return {
        ...state,
        selectedDocExplorer: action.data
      };
    case SETINTIALSTATEFOLDER:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
