import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as PrintReport } from '../../../../icons/printReport.svg';
import { IconButton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  timeText: {
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  padding: {
    paddingRight: '20px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '5px'
    }
  },
  questionText: {
    fontSize: '18px',
    paddingTop: '8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      paddingTop: '2px'
    }
  },
  flex: {
    display: 'flex',
    'justify-content': 'space-between'
  },
  iconBg: {
    marginLeft: '10px!important',
    backgroundColor: 'rgba(0, 0, 0, 0.04)!important',
    borderRadius: '3px!important',
    height: '34px!important'
  }
}));

function UserComponent({ chatKey, messageData, handlePrint }) {
  const classes = useStyles();
  return (
    <>
      <div id="askBotChildMessageEndRef" className={classes.flex}>
        {/* <Typography
          style={{ color: '#888888', fontWeight: 500 }}
          id="askBotTypoMessageEndRef"
          className={classes.timeText}
        >
          {messageData.question_time}
        </Typography> */}
        <Typography
          id="askBotTypo2MessageEndRef"
          style={{
            color: '#171717',
            'font-family': 'Satoshi',
            'font-size': '16px',
            'font-style': 'normal',
            'font-weight': '700',
            'line-height': 'normal'
          }}
          className={classes.questionText}
        >
          {messageData.question}
        </Typography>
        <IconButton
          style={{ marginLeft: '10px' }}
          className={`${classes.iconBg} print-icon`}
          onClick={() => handlePrint(chatKey)}
        >
          <PrintReport />
        </IconButton>
      </div>
    </>
  );
}

export default UserComponent;
