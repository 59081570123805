import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import ScreenerTab from './screenerTab';
import ScreenerTable from './common/table';
import ScreenerMainSearch from './common/search';
import { useSelector } from 'react-redux';
import LoadingPopover from '../shared/loadingPopover/loadingPopover';
import ErrorToast from '../atoms/errorToast';

const Screener = () => {
  const classes = useStyles();
  const enableScreener = process.env.REACT_APP_ENABLE_SCREENER === 'true' ?? false;
  const selectedScreenerTab = useSelector((state) => state.screenerReducer.selectedScreenerTab);
  const screenerData = useSelector((state) => state.screenerReducer.screenerData);
  const currentTabDataIndex = screenerData.findIndex(
    (screenerTab) => screenerTab.entityType === selectedScreenerTab
  );
  const errorMessage = useSelector((state) => state.screenerReducer.errorMessage);

  const currentTabData = screenerData[currentTabDataIndex];
  const { tableData = {}, isLoading } = currentTabData || {};
  const { items = [] } = tableData || {};

  const navigate = useNavigate();

  useEffect(() => {
    if (!enableScreener) {
      navigate('/home');
    }
  }, []);

  const showTable = (items && items.length > 0) || isLoading;
  return (
    <>
      <ErrorToast errorMessage={errorMessage} />
      <LoadingPopover open={currentTabData.isLoading} />
      <div>
        <div style={{ backgroundColor: '#F7F9FD' }}>
          <div style={{ maxWidth: '1440px', margin: '0 auto' }}>
            <div style={{ padding: '10px 40px 16px 40px' }}>
              <Typography className={classes.screenerTxt}>
                <span
                  style={{
                    backgroundImage: 'linear-gradient(to right, #004CFF 0%, #06BCC1 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                >
                  Screening
                </span>
              </Typography>
              <ScreenerTab />
              <ScreenerMainSearch />
            </div>
          </div>
        </div>
        <div style={{ maxWidth: '1440px', margin: '0 auto' }}>{showTable && <ScreenerTable />}</div>
      </div>
      {!showTable && <ScreenerTable />}
    </>
  );
};

export default Screener;
