import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setScreenerData } from '../../../../redux/pages/screener/action';
import QueryActionBtns from './queryAction';
import { TextField } from '@material-ui/core';
import { Box } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import useStyles from '../../styles';
import { getScreenrTableData } from '../../services';

const TextQuery = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const screenerData = useSelector((state) => state.screenerReducer.screenerData);
  const selectedTabData = screenerData.find((item) => item.active === true);
  const uuid = useSelector((state) => state.tokenReducer.uuID);

  const handleQuestionSubmit = useCallback(async () => {
    let body = {
      entity_type: selectedTabData.entityType,
      query_type: selectedTabData.filterType,
      query_details: {
        query_text: selectedTabData.queryText
      },
      session_id: uuid
    };
    let params = `?page_number=${1}&page_size=${selectedTabData.pageSize}&session_id=${uuid}`;
    try {
      const tableData = await getScreenrTableData(dispatch, screenerData)(body, params);

      const updatedScreenerData = screenerData.map((screenerItem) =>
        screenerItem.active
          ? {
              ...screenerItem,
              tableData: tableData.data.data,
              isLoading: false,
              tableDataError: false,
              tableDataErrorMessage: ''
            }
          : screenerItem
      );
      dispatch(setScreenerData(updatedScreenerData));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, screenerData]);

  const handleInputChange = useCallback(
    (e) => {
      const updatedScreenerData = screenerData.map((item) =>
        item.active ? { ...item, queryText: e.target.value } : item
      );
      dispatch(setScreenerData(updatedScreenerData));
    },
    [dispatch, screenerData]
  );

  const handleSuggestedQueryClick = useCallback(
    (e, item) => {
      const updatedScreenerData = screenerData.map((screenerItem) =>
        screenerItem.active ? { ...screenerItem, queryText: item } : screenerItem
      );
      dispatch(setScreenerData(updatedScreenerData));
    },
    [dispatch, screenerData]
  );

  return (
    <>
      <div style={{ paddingTop: '10px' }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={classes.inputContainer}
        >
          <TextField
            fullWidth
            variant="outlined"
            disabled={selectedTabData.isLoading}
            placeholder="Write your own query"
            className={classes.root}
            value={selectedTabData.queryText}
            onInput={handleInputChange}
            multiline
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                handleQuestionSubmit();
              }
            }}
          />
        </form>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              whiteSpace: 'nowrap',
              width: '100%',
              paddingTop: '16px'
            }}
          >
            {selectedTabData.exampleQueries.length > 0 && (
              <div
                style={{ background: '#EAF1FE', height: '48px', borderRadius: '4px' }}
                className={classes.noScrollbarVertical}
              >
                {selectedTabData.exampleQueries.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: item?.report_name === '' ? 'none' : 'inline-block',
                      height: '34px',
                      textAlign: 'center',
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #FFFFFF',
                      margin: '6px 10px',
                      borderRadius: '4px',
                      padding: '0px 10px',
                      alignContent: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={(e) => handleSuggestedQueryClick(e, item)}
                  >
                    <Box
                      sx={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid none',
                        borderRadius: '8px',
                        padding: '0px',
                        minHeight: '34px',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <div className={classes.reportBox}>
                        <Typography className={classes.templateName}>{item}</Typography>
                      </div>
                    </Box>
                  </Box>
                ))}
              </div>
            )}
          </Box>
        </Box>
      </div>
      <QueryActionBtns />
    </>
  );
};

export default TextQuery;
